<template>
    <div>
        <van-field clickable @click="show_JieKouSelect = true" v-model="device.jieKouCompanyName" readonly label="选择接口">
        </van-field>
        <div v-if="device.jieKouCompanyName == '艾赛克'">
            <van-field v-model="model1.tongDao1Name" label="通道1名称">
            </van-field>
            <van-field v-model="model1.tongDao2Name" label="通道2名称">
            </van-field>
            <van-field v-model="model1.tongDao3Name" label="通道3名称">
            </van-field>
            <van-field v-model="model1.tongDao4Name" label="通道4名称">
            </van-field>
            <van-field v-model="model1.tongDao5Name" label="通道5名称">
            </van-field>
            <van-field v-model="model1.tongDao6Name" label="通道6名称">
            </van-field>
            <van-field v-model="model1.tongDao7Name" label="通道7名称">
            </van-field>
            <van-field v-model="model1.tongDao8Name" label="通道8名称">
            </van-field>
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany1">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col>
        </div>
        <div v-else-if="device.jieKouCompanyName == '河北佳泽'">
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany2">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col>
        </div>
        <div v-else-if="device.jieKouCompanyName == '天津亚诚科技'">
            <van-field v-model="model3.epsId" label="epsId">
            </van-field>
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany3">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col>
        </div>
        <div v-else-if="device.jieKouCompanyName == '青岛一开'">
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany4">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col>
        </div>
        <div v-else-if="device.jieKouCompanyName == '青岛易建'">
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany4">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col>
        </div>
        <div v-else-if="device.jieKouCompanyName == '河北金蟾'">
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany4">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col>
        </div>
        <van-popup v-model="show_JieKouSelect" position="bottom">
            <van-picker title="标题" show-toolbar value-key="dataText" :columns="columns" @confirm="onConfirm"
                @cancel="show_JieKouSelect = false"></van-picker>
        </van-popup>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
    props: ["serialNumber"],
    data() {
        return {
            show_JieKouSelect: false,
            columns: [],
            model1: {},
            model2: {},
            model3: {},
            device: { jieKouCompanyName: "" },
        };
    },
    methods: {
        onConfirm(v) {
            let that = this;
            that.show_JieKouSelect = false;
            that.device.jieKouCompanyName = v.dataValue;
            that.loadJieKouComapny(v.dataValue);
        },
        loadJieKouComapny(jieKouCompanyName) {
            let that = this;
            //接口方没有配置的情况下，这里就不用写
            if (jieKouCompanyName == "艾赛克") {
                that.getCompany1(jieKouCompanyName);
            } else if (jieKouCompanyName == "河北佳泽") {
                that.getCompany2(jieKouCompanyName);
            } else if (jieKouCompanyName == "天津亚诚科技") {
                that.getCompany3(jieKouCompanyName);
            }

        },
        jieBang() {
            let that = this;
            that.$dialog
                .confirm({ message: "确定要删除接口吗？" })
                .then(function () {
                    that.axios.post("WXCW_JieKou/JieBang", { onlyText: that.serialNumber }).then(function (response) {
                        that.device = response.data.data;
                        that.$notify({ type: "success", message: "删除接口成功" });
                    });
                })
                .catch(function () { });
        },
        getCompany1() {
            let that = this;
            that.axios
                .post("WXCW_JieKou/GetJieKou1", {
                    onlyText: that.serialNumber,
                })
                .then(function (response) {
                    that.model1 = response.data.data;
                });
        },
        saveCompany1() {
            let that = this;
            that.axios.post("WXCW_JieKou/SaveJieKou1", that.model1).then(function (response) {
                that.model1 = response.data.data;
                that.$notify({ type: "success", message: "保存接口成功" });
            });
        },
        getCompany2() {
            let that = this;
            that.axios
                .post("WXCW_JieKou/GetJieKou2", {
                    onlyText: that.serialNumber,
                })
                .then(function (response) {
                    that.model2 = response.data.data;
                });
        },
        saveCompany2() {
            let that = this;
            that.axios.post("WXCW_JieKou/SaveJieKou2", that.model2).then(function (response) {
                that.model2 = response.data.data;
                that.$notify({ type: "success", message: "保存接口成功" });
            });
        },
        getCompany3() {
            let that = this;
            that.axios
                .post("WXCW_JieKou/GetJieKou3", {
                    onlyText: that.serialNumber,
                })
                .then(function (response) {
                    that.model3 = response.data.data;
                });
        },
        saveCompany3() {
            let that = this;
            that.axios.post("WXCW_JieKou/SaveJieKou3", that.model3).then(function (response) {
                that.model3 = response.data.data;
                that.$notify({ type: "success", message: "保存接口成功" });
            });
        },
        //这个公司只保存设备数据，没有任何配置项就用这个方法
        saveCompany4() {
            let that = this;
            that.axios.post("Base_Device/UpdateModel", that.device).then(function () {
                that.$notify({ type: "success", message: "保存接口成功" });
            });
        },
    },
    mounted() {
        let that = this;
        that.axios
            .post("Base_SystemBaseData/GetListByDataType", {
                dataType: "接口公司名称",
            })
            .then(function (response) {
                that.columns = response.data.data;
            });
        //获取选择的接口公司名称
        that.axios
            .post("Base_Device/GetModelBySerialNumber", {
                serialNumber: that.serialNumber,
            })
            .then(function (response) {
                console.log(response);
                that.device = response.data.data;
                that.loadJieKouComapny(that.device.jieKouCompanyName);
            });
    },
};
</script>

<style scoped></style>